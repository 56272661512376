// Components
import Loader from "components/Loader"

// Hooks
import useContinue from "./useContinue"

const Continue = () => {
  useContinue()
  return <Loader />
}

export default Continue
