//Components
import AccessibleVehiclesLoader from "@vehicles/components/Loader"

//Style
import "./index.scss"

//Assets
import logoItau from "images/itau-logo.png"

const Loader = ({ hideLogo = false }) => {
  return (
    <AccessibleVehiclesLoader
      hideLogo={hideLogo}
      logo={logoItau}
      logoAlt="Logo Itaú Unibanco"
    />
  )
}

export default Loader
