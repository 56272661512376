import { useEffect, useContext } from "react"
import { navigate } from "gatsby"

// Services
import services from "services"
import { updateToken } from "services/auth"

// Utils
import { getUrlParamAll, getUrlParam } from "utils/browser"
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import getTrackingData from "utils/getTrackingData"

// Contexts
import { Context } from "contexts/simulation"

const useContinue = () => {
  const { simulation, setSimulation } = useContext(Context)

  useEffect(() => {
    const initContinuation = async () => {
      const { leadUUID, lead_uuid, ...args } = getUrlParamAll()
      const token = getUrlParam("token")
      const UUID = leadUUID ?? lead_uuid
      setSimulation({ leadUUID: UUID })

      const trackingData = await getTrackingData()

      const simulationRecovered = await services.recovery
        .recoverSimulation({
          analytics: trackingData,
          leadUUID: UUID,
          ...args,
          token,
        })
        .catch(() => {
          navigate("/")
        })

      const data = simulationRecovered?.data

      if (data?.auth) {
        updateToken(simulationRecovered?.data?.auth)
        setSimulation({
          ...simulation,
          currentFormStep: data?.lastCompletedStep,
        })
        return navigate(
          `/formulario/${getQueryStringFromObject({
            leadUUID: data?.leadUUID,
            ...args,
          })}`
        )
      }
    }

    initContinuation()
  }, [])
}

export default useContinue
